export default [
  {
    path: '/projects/project-list',
    name: 'project-list',
    component: () => import('@/views/projects/project-list/ProjectList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
