export default [
  {
    path: '/quote/project-list',
    name: 'quote-list',
    component: () => import('@/views/quotes/quotes-list/QuoteList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
