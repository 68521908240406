export default [
  {
    path: "/contact/contact-list",
    name: "contact-list",

    component: () =>
      import("@/views/contact/contact-list/CustomerListShow.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/contact/contact-edit/:id",
    name: "contact-edit",
    component: () => import("@/views/contact/contact-add/CustomerEdit.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/contact/contact-add",
    name: "contact-add",
    component: () => import("@/views/contact/contact-add/CustomerAdd.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/contact/contact-add-purchase",
    name: "contact-add-purchase",
    component: () => import("@/views/contact/contact-add/CustomerAdd.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/contact/purchase-contact-edit/:id",
    name: "purchase-contact-edit",
    component: () => import("@/views/contact/contact-add/CustomerEdit.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/contact/contact-edit-purchase/:id/:quoteid",
    name: "contact-edit-purchase",
    component: () => import("@/views/contact/contact-add/CustomerEdit.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/contact/contact-edit-sale/:id/:quoteid",
    name: "contact-edit-sale",
    component: () => import("@/views/contact/contact-add/CustomerEdit.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/contact/contact-edit-inception/:id/:quoteid",
    name: "contact-edit-inception",
    component: () => import("@/views/contact/contact-add/CustomerEdit.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
];
