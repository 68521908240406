export default [
  {
    path: "/todo/todo-dashboard",
    name: "todo-dashboard",
    component: () => import("@/views/todo/TodoDashboard.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
];
