export default [
  {
    path: "/files/file-list",
    name: "file-list",

    component: () => import("@/views/files/files-list/FileList.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
];
