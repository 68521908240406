export default [
  {
    path: '/leads/leads-list',
    name: 'leads-list',

    component: () => import('@/views/leads/LeadsList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  // {
  //   path: '/leads/junk-leads-list',
  //   name: 'junk-leads-list',

  //   component: () => import('@/views/leads/JunkLeadList.vue'),
  //   meta: {
  //     Permission: 'no_permission_required',
  //   },
  // },

  {
    path: '/leads/leads-dashboard',
    name: 'leads-dashboard',
    component: () => import('@/views/leads/LeadsDashboard.vue'),
    meta: {
      Permission: 'no_permission_required',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },

  {
    path: '/leads/leads-dashboard/:filter',
    name: 'leads-dashboard-filter',
    component: () => import('@/views/leads/LeadsDashboard.vue'),
    meta: {
      Permission: 'no_permission_required',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'leads-dashboard',
    },
    // beforeEnter(to, _, next) {
    //   if (['new', 'hot', 'won', 'died'].includes(to.params.filter)) next();
    //   else next({ name: 'error-404' });
    // },
  },
  {
    path: '/leads/leads-dashboard/team/:team',
    name: 'leads-dashboard-team',
    component: () => import('@/views/leads/LeadsDashboard.vue'),
    meta: {
      Permission: 'no_permission_required',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'leads-dashboard',
    },
  },

  {
    path: '/leads/leads-add',
    name: 'leads-add',
    component: () => import('@/views/leads/LeadsAdd.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/leads/leads-edit/:id',
    name: 'leads-edit',
    component: () => import('@/views/leads/LeadsEdit.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/leads/leads-preview/:id',
    name: 'leads-preview',
    component: () => import('@/views/leads/LeadsPreview.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
