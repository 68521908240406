export default [
  {
    path: "/scopes/scopes-list",
    name: "scopes-list",

    component: () => import("@/views/scopes/ScopeList.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/scopes/junk-scopes-list",
    name: "junk-scopes-list",

    component: () => import("@/views/scopes/ArchiveScopeList.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/scopes/scope-add",
    name: "scope-add",
    component: () => import("@/views/scopes/ScopeAdd.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/scopes/scopes-edit/:id",
    name: "scopes-edit",
    component: () => import("@/views/scopes/ScopeEdit.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/scopes/scopes-preview/:id",
    name: "scopes-preview",
    component: () => import("@/views/scopes/ScopePreview.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
];
