export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      resource: 'settings',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },
];
