export default [
  {
    path: '/clockify/client-list',
    name: 'clockify-client',

    component: () => import('@/views/clockify/ClientList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/clockify/add-client',
    name: 'clockify-add-client',

    component: () => import('@/views/clockify/AddClient.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'clockify-client',
    },
  },

  {
    path: '/clockify/edit-client/:id',
    name: 'clockify-edit-client',

    component: () => import('@/views/clockify/EditCustomer.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'clockify-client',
    },
  },

  {
    path: '/clockify/project-list',
    name: 'clockify-project',

    component: () => import('@/views/clockify/ProjectList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/clockify/add-project',
    name: 'clockify-add-project',

    component: () => import('@/views/clockify/AddProject.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'clockify-project',
    },
  },

  {
    path: '/clockify/edit-project/:id',
    name: 'clockify-edit-project',

    component: () => import('@/views/clockify/EditProject.vue'),
    meta: {
      Permission: 'no_permission_required',
      navActiveLink: 'clockify-project',
    },
  },

  {
    path: '/clockify/user-reports',
    name: 'clockify-user-reports',
    component: () => import('@/views/clockify/EmployeeReport.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/clockify/client-reports',
    name: 'clockify-client-reports',
    component: () => import('@/views/clockify/ClientReport.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
