import { $themeBreakpoints } from '@themeConfig';

export default {
  namespaced: true,

  state: {
    // baseURL: 'https://api.portal.tmss-repo.com/storage/app/public/images/',
    // profileURL: 'https://api.portal.tmss-repo.com/storage/app/public/profile/',
    // fileURL: 'https://api.portal.tmss-repo.com/storage/app/public/files/',
    // excelFileURL: 'https://api.portal.tmss-repo.com/storage/app/public/excel_files/',
    // purchaseOrderURL: 'https://api.portal.tmss-repo.com/storage/app/public/purchase/',
    // saleOrderURL: 'https://api.portal.tmss-repo.com/storage/app/public/sale/',
    // settingURL: 'https://api.portal.tmss-repo.com/storage/app/public/settings/',
    // leadFileURL: 'https://api.portal.tmss-repo.com/storage/app/public/Leads/',

    baseURL: 'https://api.demo8.portfolio.tmss-repo.com/storage/app/public/images/',
    profileURL: 'https://api.demo8.portfolio.tmss-repo.com/storage/app/public/profile/',
    fileURL: 'https://api.demo8.portfolio.tmss-repo.com/storage/app/public/files/',
    excelFileURL: 'https://api.demo8.portfolio.tmss-repo.com/storage/app/public/excel_files/',
    purchaseOrderURL: 'https://api.demo8.portfolio.tmss-repo.com/storage/app/public/purchase/',
    saleOrderURL: 'https://api.demo8.portfolio.tmss-repo.com/storage/app/public/sale/',
    settingURL: 'https://api.demo8.portfolio.tmss-repo.com/storage/app/public/settings/',
    leadFileURL: 'https://api.demo8.portfolio.tmss-repo.com/storage/app/public/Leads/',

    windowWidth: 0,
    shallShowOverlay: false,
    token: '',
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return 'xl';
      if (windowWidth >= $themeBreakpoints.lg) return 'lg';
      if (windowWidth >= $themeBreakpoints.md) return 'md';
      if (windowWidth >= $themeBreakpoints.sm) return 'sm';
      return 'xs';
    },

    isAuthenticated(state) {
      return state.token !== '';
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
  },
  actions: {},
};
