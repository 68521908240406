export default [
  {
    path: '/invoice/project-list',
    name: 'invoice-list',
    component: () => import('@/views/invoices/invoices-list/InvoiceList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
